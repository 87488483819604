import AbstractEntity from "./AbstractEntity";
import Question from "@/api/types/Question";
import AddOnEntity from "@/db/AddOnEntity";
import AnswerEntity from "@/db/AnswerEntity";
import AnswerDependencyEntity from "@/db/AnswerDependencyEntity";
import QuestionDependencyEntity from "@/db/QuestionDependencyEntity";

const TABLE_NAME = 'Question'

export default class QuestionEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionnaireId',
            'parentQuestionId',
            'type',
        ])
    }

    public static async persist(db: IDBDatabase, question: Question) {
        const data = super.getPersistData<Question>(question, [
            'id',
            'questionnaireId',
            'topicId',
            'categoryId',
            'number',
            'subNumber',
            'numberString',
            'question',
            'mandatory',
            'information',
            'parentQuestionId',
            'type',
            'plausibility',
            'farmType',
        ])

        await super._put(db, TABLE_NAME, data)

        for (const addon of question.addOns) {
            await AddOnEntity.persist(db, addon)
        }

        for (const answer of question.answers) {
            await AnswerEntity.persist(db, answer)
        }

        for (const answerDependency of question.dependencies) {
            await AnswerDependencyEntity.persist(db, answerDependency)
        }

        for (const questionDependency of question.questionDependencies) {
            await QuestionDependencyEntity.persist(db, questionDependency)
        }
    }

    static async getParentQuestions(db: IDBDatabase, questionnaireId: number, farmType: number | null, categoryFilter: number[] = []) {
        const questions = await this._find<Question>(
            db,
            TABLE_NAME,
            questionnaireId,
            'questionnaireId',
            (q: Question) => {
                if (q.parentQuestionId) {
                    return false
                }
                if (farmType && q.farmType && q.farmType !== farmType) {
                    return false
                }
                if (q.categoryId && categoryFilter.length && !categoryFilter.includes(q.categoryId)) {
                    return false
                }
                return true
            }
        )

        return questions.sort(this.sort)
    }

    static async getSubQuestions(db: IDBDatabase, parentId: number) {
        const questions = await this._find<Question>(
            db,
            TABLE_NAME,
            parentId,
            'parentQuestionId'
        )

        return questions.sort(this.sort)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }

    private static sort = (a: Question, b: Question) => {
        const numberA = a.number || 0
        const numberB = b.number || 0

        const subNumberA = a.subNumber || 0
        const subNumberB = b.subNumber || 0

        if (numberA === numberB) {
            return subNumberA > subNumberB ? 1 : -1
        }

        return numberA > numberB ? 1 : -1
    }
}