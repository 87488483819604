import {Store} from "vuex";
import {AppState} from "@/store";
import User from "@/api/types/User";
import QuestionEntity from "@/db/QuestionEntity";
import Question from "@/api/types/Question";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import QuestionnaireCategory from "@/api/types/QuestionnaireCategory";
import UserQuestion from "@/api/types/UserQuestion";
import Answer from "@/api/types/Answer";
import UserQuestionEntity from "@/db/UserQuestionEntity";
import getDb from "@/db/Database";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import QuestionnaireTopicEntity from "@/db/QuestionnaireTopicEntity";
import QuestionnaireCategoryEntity from "@/db/QuestionnaireCategoryEntity";
import AnswerEntity from "@/db/AnswerEntity";
import AddOnEntity from "@/db/AddOnEntity";
import UserAnswer from "@/api/types/UserAnswer";
import UserAnswerEntity from "@/db/UserAnswerEntity";
import AnswerDependencyEntity from "@/db/AnswerDependencyEntity";
import QuestionDependencyEntity from "@/db/QuestionDependencyEntity";
import DependencyQuestionAnswerEntity from "@/db/DependencyQuestionAnswerEntity";

export type RawAnswer = number|number[]|string|null

export enum QuestionType {
    TYPE_TEXT = 1,
    TYPE_NUMERIC = 2,
    TYPE_CHOICE = 3,
    TYPE_MULTIPLE_CHOICE = 4,
    TYPE_DATE = 5,
}

export type QuestionEntry = Question & {
    parentIndex: number
    displayed: boolean
    topic: QuestionnaireTopic|null
    category: QuestionnaireCategory|null
    userQuestion: UserQuestion|null
    selectedAnswers: Answer[]
}

export default class QuestionHandler {
    private store: Store<AppState>
    private db: IDBDatabase

    private questions: QuestionEntry[] = []
    private indexMap: { [ questionId: number ]: number } = {}

    private currentQuestion: Question|null = null
    private currentUserQuestion: UserQuestion|null = null
    private questionIndex = 0

    constructor(store: Store<AppState>, db: IDBDatabase) {
        this.store = store
        this.db = db
    }

    public async loadQuestions(
        questionnaireId: number,
        userQuestionnairePk: number,
        user: User,
        categoryFilter: number[],
    ) {
        const questions = await QuestionEntity.getParentQuestions(
            this.db,
            questionnaireId,
            user.farmType,
            categoryFilter,
        )

        this.clear()

        const userQuestionnaire = await UserQuestionnaireEntity.findByPk(this.db, userQuestionnairePk)

        if (userQuestionnaire) {
            for (const question of questions) {
                await this.initQuestion(
                    question,
                    userQuestionnaire,
                    user,
                    categoryFilter,
                    -1
                )
            }
        }

        this.persistToStore()
    }

    public clear() {
        this.questions = []
        this.indexMap = {}
        this.currentQuestion = null
        this.currentUserQuestion = null
        this.questionIndex = 0
        this.store.commit('check/CLEAR')
    }

    private persistToStore() {
        this.store.commit('check/SET_QUESTIONS', this.questions)
        this.store.commit('check/SET_CURRENT_QUESTION', this.currentQuestion)
        this.store.commit('check/SET_CURRENT_USER_QUESTION', this.currentUserQuestion)
        this.store.commit('check/SET_QUESTION_INDEX', this.questionIndex)
    }

    public async saveAnswer(questionIndex: number, answer: RawAnswer) {
        const question = this.questions[questionIndex]
        let questionnaire = <UserQuestionnaire>this.store.state.currentQuestionnaire

        if (!question) {
            throw new Error(`failed to load question with index ${questionIndex}`)
        }

        if (question.userQuestion) {
            question.userQuestion.answers = []

            if ([QuestionType.TYPE_CHOICE, QuestionType.TYPE_MULTIPLE_CHOICE].includes(<number>question.type)) {
                if (answer) {
                    if (question.type === QuestionType.TYPE_CHOICE) {
                        answer = [<number>answer]
                    }
                    for (const answerId of <number[]>answer) {
                        question.userQuestion.answers.push(new UserAnswer({
                            userQuestionPk: question.userQuestion.pk,
                            answerId,
                            questionId: question.id,
                        } as UserAnswer))
                    }
                }
            } else if ([QuestionType.TYPE_TEXT].includes(<number>question.type)) {
                question.userQuestion.stringValue = <string>answer
            } else if ([QuestionType.TYPE_DATE].includes(<number>question.type)) {
                question.userQuestion.dateValue = <string>answer
            } else if (question.type === QuestionType.TYPE_NUMERIC) {
                if (question.plausibility) {
                    const checker: QuestionPlausibilityChecker = new QuestionPlausibilityChecker(question.plausibility)
                    if (!checker.check) {
                        throw new Error(`Sie haben einen ungültigen Wert eingegeben! Wertebereich: ${question.plausibility}`)
                    }
                }
                question.userQuestion.numberValue = <number>answer
            }

            questionnaire.dirty = 1
            questionnaire.lastQuestionId = question.id

            await UserQuestionnaireEntity.persist(this.db, questionnaire)
            await UserAnswerEntity.clearForUserQuestion(this.db, <number>question.userQuestion.pk)

            question.userQuestion.dirty = 1
            question.userQuestion = {
                ...question.userQuestion,
                ...(await UserQuestionEntity.persist(this.db, question.userQuestion))
            }

            const userAnswers: UserAnswer[] = <UserAnswer[]>await UserAnswerEntity.findForUserQuestion(getDb(), <number>question.userQuestion.pk)
            question.selectedAnswers = []
            for (const userAnswer of userAnswers) {
                question.selectedAnswers.push(<Answer>await AnswerEntity.getById(getDb(), userAnswer.answerId))
            }

            this.questions[questionIndex] = question

            this.store.commit('SET_QUESTIONNAIRE', {
                questionnaire,
                release: this.store.state.currentRelease,
            })

            for (const q of this.questions) {
                await this.checkForDisplay(q, questionnaire, false);
            }

            this.store.commit('check/SET_QUESTIONS', this.questions)
        }

        await this.handleSubQuestions(question)
    }

    private async handleSubQuestions(question: QuestionEntry) {
        const subQuestions = await QuestionEntity.getSubQuestions(this.db, question.id)

        for (const subQuestion of subQuestions) {
            const subQuestionIndex = this.indexMap[subQuestion.id]
            if (typeof subQuestionIndex === 'undefined') {
                continue;
            }
            const subQuestionEntry = this.questions[subQuestionIndex]
            if (!question.selectedAnswers) {
                subQuestionEntry.selectedAnswers = []
                if (subQuestionEntry.userQuestion) {
                    subQuestionEntry.userQuestion.stringValue = null
                    subQuestionEntry.userQuestion.numberValue = null
                    subQuestionEntry.userQuestion.dateValue = null
                    subQuestionEntry.userQuestion.answers = []
                }
            } else {
                let displayed = false
                const dependencies = await AnswerDependencyEntity.findForQuestion(getDb(), subQuestionEntry.id)
                for (const dependency of dependencies) {
                    for (const answer of question.selectedAnswers) {
                        if (dependency.answerId === answer.id) {
                            displayed = true
                            break
                        }
                    }
                }
                if (!displayed && subQuestionEntry.userQuestion) {
                    subQuestionEntry.userQuestion.stringValue = null
                    subQuestionEntry.userQuestion.numberValue = null
                    subQuestionEntry.userQuestion.dateValue = null
                    subQuestionEntry.userQuestion.answers = []
                }
                subQuestionEntry.displayed = displayed

                this.questions[subQuestionIndex] = subQuestionEntry

                await this.handleSubQuestions(subQuestionEntry)
            }
        }
    }

    private async initQuestion(
        question: Question,
        userQuestionnaire: UserQuestionnaire,
        user: User,
        categoryFilter: number[],
        parentIndex: number
    ): Promise<void> {
        const res = <QuestionEntry>{
            ...question,
            parentIndex: -1,
            displayed: false,
            topic: null,
            category: null,
            userQuestion: null,
            selectedAnswers: [],
        }

        if (!question.farmType || question.farmType === user.farmType) {
            let userQuestion = await UserQuestionEntity.get(getDb(), <number>userQuestionnaire.pk, question.id)

            res.topic = await QuestionnaireTopicEntity.getById(getDb(), <number>question.topicId)
            res.category = await QuestionnaireCategoryEntity.getById(getDb(), <number>question.categoryId)
            res.answers = await AnswerEntity.findForQuestion(getDb(), question.id)
            res.addOns = await AddOnEntity.findByQuestion(getDb(), question.id)

            res.parentIndex = parentIndex

            if (!userQuestion) {
                userQuestion = new UserQuestion({
                    userQuestionnaireId: userQuestionnaire.id,
                    userQuestionnairePk: userQuestionnaire.pk,
                    questionId: question.id,
                    dirty: 1,
                } as UserQuestion)
            } else {
                if (question.type === QuestionType.TYPE_CHOICE || question.type === QuestionType.TYPE_MULTIPLE_CHOICE) {
                    const userAnswers: UserAnswer[] = <UserAnswer[]>await UserAnswerEntity.findForUserQuestion(getDb(), <number>userQuestion.pk)
                    userQuestion.answers = userAnswers
                    for (const userAnswer of userAnswers) {
                        res.selectedAnswers.push(<Answer>await AnswerEntity.getById(getDb(), userAnswer.answerId))
                    }
                }
            }

            res.userQuestion = userQuestion

            await this.checkForAnswerDependency(res)
            await this.checkForDisplay(res, userQuestionnaire, false)

            this.indexMap[question.id] = this.questions.length
            this.questions.push(res)

            if (userQuestionnaire.lastQuestionId && question.id === userQuestionnaire.lastQuestionId) {
                const category: QuestionnaireCategory = <QuestionnaireCategory>await QuestionnaireCategoryEntity.getById(getDb(), <number>question.categoryId)
                if (categoryFilter.length && categoryFilter.includes(category.id)) {
                    this.currentQuestion = question
                    this.currentUserQuestion = userQuestion
                    this.questionIndex = this.questions.length - 1
                }
            }

            const subQuestions = await QuestionEntity.getSubQuestions(getDb(), question.id)
            const newParentIndex = this.questions.length - 1

            for (const subQuestion of subQuestions) {
                await this.initQuestion(
                    subQuestion,
                    userQuestionnaire,
                    user,
                    categoryFilter,
                    newParentIndex,
                )
            }
        }
    }

    private async checkForAnswerDependency(question: QuestionEntry) {
        let displayed = false
        if (question.parentIndex > -1) {
            displayed = false
            const parentQuestion = this.questions[question.parentIndex]
            if (parentQuestion.displayed && parentQuestion.selectedAnswers) {
                const dependencies = await AnswerDependencyEntity.findForQuestion(getDb(), question.id)
                for (const dependency of dependencies) {
                    for (const selectedAnswer of <Answer[]>parentQuestion.selectedAnswers) {
                        if (dependency.answerId === selectedAnswer.id) {
                            displayed = true
                            break
                        }
                    }
                }
            }
        }
        question.displayed = displayed
    }

    private async checkForDisplay(question: QuestionEntry, currentUserQuestionnaire: UserQuestionnaire, handleSub: boolean) {
        let displayed = true
        if (question.parentQuestionId === null) {
            const dependencies = await QuestionDependencyEntity.findForQuestion(getDb(), question.id)
            if (dependencies && dependencies.length) {
                displayed = false
                for (const dependency of dependencies) {
                    const requiredAnswers = await DependencyQuestionAnswerEntity.findForQuestionDependency(getDb(), dependency.id)
                    const userDepQuestion: UserQuestion = <UserQuestion>await UserQuestionEntity.get(getDb(), <number>currentUserQuestionnaire.pk, dependency.dependencyQuestionId)
                    if (userDepQuestion) {
                        const depAnswers = await UserAnswerEntity.findForUserQuestion(getDb(), <number>userDepQuestion.pk)
                        if (depAnswers && depAnswers.length) {
                            const givenAnswerIds = depAnswers.map(answer => answer.answerId)
                            const requiredAnswerIds = requiredAnswers.map(answer => answer.answerId)
                            for (const id of requiredAnswerIds) {
                                if (givenAnswerIds.includes(id)) {
                                    displayed = true
                                    break
                                }
                            }
                        }
                    }
                }
            }
            question.displayed = displayed
        }

        this.handleSubQuestions(question)
    }

    async setCompleted() {
        if (this.currentUserQuestion?.userQuestionnairePk) {
            await UserQuestionnaireEntity.updateByPk(getDb(), this.currentUserQuestion.userQuestionnairePk, {
                state: 3
            })
        }
    }
}
