<template>
  <app-activity title="Risiko - Analyse"
                :icons="icons"
                @icon-click="onExit"
                :fab-icons="fabIcons"
                @fab-icon-click="onFabAction"
                vertical="center"
                full-size
                id="menu-activity">
    <div class="location-selection-wrap">
      <location-selection/>
    </div>
    <ul class="main-menu">
      <li v-for="icon of menuIcons">
        <div class="item" @click="() => onMenuAction(icon.icon)">
          <div class="icon"><img :src="icon.icon"/></div>
          <div class="name">{{ icon.name }}</div>
        </div>
      </li>
    </ul>
  </app-activity>
</template>

<script lang="ts" setup>
import AppActivity from "@/components/AppActivity.vue";
import {
  ICON_CONFIGURE,
  ICON_EXIT,
  ICON_INFORMATION_ICON,
  ICON_OPTIMIZE,
  ICON_START_CHECK,
  ICON_START_REPORT
} from "@/lib/icons";
import {useStore} from "vuex";
import router from "@/router";
import {showAlert, showSnackbar} from "@/store";
import {useApiClient} from "@/api";
import {computed} from "vue";
import LocationSelection from "@/components/LocationSelection.vue";

const store = useStore()

const icons = [
    ICON_EXIT,
]

const fabIcons = [
    ICON_INFORMATION_ICON,
]

const menuIcons: { icon: string, name: string }[] = [
  {
    icon: ICON_START_CHECK,
    name: 'Check starten'
  },
  {
    icon: ICON_START_REPORT,
    name: 'Ergebnisse'
  },
  {
    icon: ICON_OPTIMIZE,
    name: 'Optimierungs- vorschläge'
  },
  {
    icon: ICON_CONFIGURE,
    name: 'Einstellungen'
  },
]

const isOffline = computed(() => store.state.offline.isOffline)

const onMenuAction = (icon: string) => {
  switch (icon) {
    case ICON_START_CHECK:
      router.push({ name: 'questionnaire', params: { mode: 'check' }})
      break;
    case ICON_START_REPORT:
      if (isOffline.value) {
        showAlert("Sie sind OFFLINE. Die Fragebögen können nur ausgewertet werden, wenn Sie mit dem Internet verbunden sind!")
      } else {
        router.push({ name: 'questionnaire', params: { mode: 'analyze' }})
      }
      break;
    case ICON_OPTIMIZE:
      if (isOffline.value) {
        showAlert("Sie sind OFFLINE. Die Optimierungen können nur abgerufen werden, wenn Sie mit dem Internet verbunden sind!")
      } else {
        router.push({ name: 'questionnaire', params: { mode: 'optimize' }})
      }
      break;
    case ICON_CONFIGURE:
      router.push({ name: 'login' })
      break
    default:
      showAlert(menuIcons.filter(i => i.icon === icon)[0].name)
  }
}

const onFabAction = (icon: string) => {
  if (icon === ICON_INFORMATION_ICON) {
    showSnackbar('Current-Version: 12')
  }
}

const onExit = () => {
  if (navigator.app) {
    navigator.app.exitApp();
  } else if (navigator.device) {
    navigator.device.exitApp();
  } else {
    window.close();
  }
}
</script>

<style lang="scss">
#menu-activity {
  .location-selection-wrap {
    margin: 15px 0;
  }

  ul.main-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: calc(var(--activity-height) - 160px);

    li {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .item {
        width: 120px;
        align-self: center;
        cursor: pointer;
      }

      .name {
        margin-top: 3px;
        font-size: 16pt;
        line-height: 18pt;
        font-weight: 500;
      }
    }
  }
}
</style>
